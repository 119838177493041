import React, { FC, useState, useContext } from 'react';
import classnames from 'classnames';
import { getPathForLanguage } from 'utils/functions';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import PlainImage from 'common/Image/PlainImage';
import Button from 'common/Button';
import { SPACEBAR_KEY_CODE } from './constants';
import { LanguageSelectorProps } from './models';
import './LanguageSelector.scss';

const LanguageSelector: FC<LanguageSelectorProps> = ({ langProps }) => {
  const {
    languageSelector: { defaultLang, languages },
  }: { languageSelector: PageContent.LanguageSelector } = useStaticQuery(graphql`
    {
      languageSelector {
        languages {
          properties {
            iso
            name
            flag {
              fallbackUrl
              url
            }
          }
        }
        defaultLang
        urls {
          ...languageUrls
        }
      }
    }
  `);

  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const { isXl: isDesktopType } = screenRecognitionContext;

  const langUrls = langProps?.urls;
  const currentLanguage = langProps?.lang || defaultLang;
  const fullLangName = languages.find((lng) => lng.properties.iso === currentLanguage)?.properties
    .name;

  const [isOpen, setIsOpen] = useState<Boolean>(false);

  const handleBazaarVoice = () => {
    if (document.getElementById('bazaar_voice') && window.BV) {
      // TODO: If BV developers will create proper handler for static pages then remove that
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleOneTrust = (code: string) => {
    (window as any).OneTrust?.changeLanguage(code);
  };

  const handleKeyClick = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === SPACEBAR_KEY_CODE) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const lngsContainerClasses = classnames('language-selector', {
    'language-selector--open': isOpen,
    'language-selector--mobile': !isDesktopType,
  });

  const lngsListClasses = classnames('languages-list', {
    'languages-list--mobile': !isDesktopType,
  });

  return langUrls && languages.length > 1 ? (
    <section className={lngsContainerClasses} data-testid="language-selector">
      <>
        {isDesktopType ? (
          <Button
            className="language-selector__switcher"
            ariaLabel={currentLanguage}
            onKeyDown={handleKeyClick}
          >
            <i className="fa fa-angle-down" />
            {fullLangName}
          </Button>
        ) : null}

        <ul className={lngsListClasses}>
          {languages.map(({ properties: { name, iso, flag } }) => (
            <li key={name}>
              <Link
                className="languages-list__link"
                to={getPathForLanguage(langUrls, iso)}
                onClick={() => {
                  handleBazaarVoice();
                  handleOneTrust(iso);
                }}
              >
                {isDesktopType && flag?.url ? (
                  <PlainImage className="languages-list__flag" src={flag.url} alt={name} />
                ) : null}
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </>
    </section>
  ) : null;
};

export default LanguageSelector;
